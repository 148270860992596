import React from 'react';
import App from '../utilities/App';

const topics = ["Cross Entropy", "Lasso Regression", "Beginner quiz", "Intermediate Quiz","Optimization","Main","Home","Speed",
"Newton Raphson","ODE Basics","PDE basics","Dot Product", "Eigen Vector","Backprop", "Vision Transformer", "Positional Embedding",
"Q1 2024", "Search","Backprop Regress", "RNN Intro", "RNN Finetune", "Embeddings Intro", "Transformers Intro", "Gradient Intro", "Backprop Classify",
"Scrapping", "DsPy", "Transformers Applications", "Vector Database", "Fama French Pytorch", "Fama French", "Perceptron", "Multi layer perceptron",
"VAE", "About Me", "Public", "AI", "VAE intro", "Prerequisites", "Function Calling","Transformers Forecasting", "Graph Transformers", "MCE", "KG Embeddings", "MLE", "Genus",
"Llama Qdrant","GraphRag","PCA","Corective RAG Local","Planning Agent", "Crew Agent", "RL Options"];

function DLApp() {
  return <App topics={topics} baseRoute="dl" />;
}

export default DLApp;
