import React from 'react';
import { useContext } from 'react';
import PaymentContext from "./paymentContext";
import GoogleSignInButton from "./GoogleSignInButton";
import StripeBuyButton from "./StripeBuyButton";

const PaymentCheck = () => {
  const { paymentFlag } = useContext(PaymentContext);

  if (!paymentFlag) {
    return (
      <div style={{ 
        width: '400px', 
        margin: '0 auto', 
        marginTop: '50px', 
        textAlign: 'center', 
        color: '#E0E0E0', 
        fontFamily: 'Roboto, Arial, sans-serif' // Using Roboto, with Arial as fallback.
      }}>
        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ color: '#B0B0B0', fontFamily: 'Roboto, Arial, sans-serif' }}>
            Step 1: Sign in with Google
          </h2>
          <GoogleSignInButton />
        </div>
        <br/>
        <br/>
        <div>
          <h2 style={{ color: '#B0B0B0', fontFamily: 'Roboto, Arial, sans-serif' }}>
            Step 2: Complete your subscription
          </h2>
          <br/>
        <br/>
          <StripeBuyButton />
        </div>
      </div>
    );
  }
  return null;
}

export default PaymentCheck;
