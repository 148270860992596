import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { Container, CssBaseline, makeStyles, AppBar, Toolbar, ThemeProvider, createMuiTheme } from '@material-ui/core';
import Sidebar from './Sidebar';
import Content from './Content';
import TopicsBar from "./TopicsBar"


const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff4e4', // Your desired background color
    },
  },
  // ... any other theme overrides
});


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff4e4', // This will set the background color for the entire component

  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function App({ topics, baseRoute }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <Router>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {/* Removed the Toggle Sidebar button */}
        </Toolbar>
      </AppBar>
      <Container className={classes.root}>
        <div className={classes.content}>
          <Toolbar /> {/* This ensures that the content is not under the AppBar */}
          <Switch>
            <Route path={`/${baseRoute}`} component={() => <Wrapper topics={topics} baseRoute={baseRoute} />} />
          </Switch>
        </div>
      </Container>
    </Router>
    </ThemeProvider>
  );
}

function Wrapper({ topics, baseRoute }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let topic = params.get('topic');
  if (!topic) {
    topic = topics[0].toLowerCase().split(' ').join('-');  // Convert the first topic from the topics array to a dash-separated string
  }

  return (
    <>
      {/* <TopicsBar topics={topics} baseRoute={baseRoute} /> */}
      {/* <Sidebar topics={topics} baseRoute={baseRoute} /> */}
      <Content topic={topic} basePath={baseRoute} />
    </>
  );
}

export default App;
