import React, { useState, useEffect } from 'react';
import './QuizComponent.css';

const QuizRadioButtons = ({ categoryName, questions }) => {
  const [responses, setResponses] = useState({});
  const [score, setScore] = useState(0);

  // Debug: useEffect to log changes in responses and score
  useEffect(() => {
    console.log('Responses:', responses);
    console.log('Score:', score);
  }, [responses, score]);

  const handleOptionChange = (questionId, selectedOption) => {
    const updatedResponses = {
      ...responses,
      [questionId]: selectedOption,
    };
    setResponses(updatedResponses);

    // Calculate score based on updated responses
    const newScore = questions.reduce((acc, question) => {
      // Correctly access `question.correctAnswer` and compare
      return acc + (updatedResponses[question.id] === question.correctAnswer ? 1 : 0);
    }, 0);
    setScore(newScore);
  };

  return (
    <div className="quiz-container">
      <div className="score-panel" style={{ position: 'sticky', top: '10px' }}>
        <h2>{categoryName} (Score: {score})</h2>
      </div>
      {questions.map((question) => (
        <div key={question.id} className="term-section">
          <h3>{question.text}</h3>
          <div className="options-container">
            {question.options.map((option) => (
              <label key={option.key} className="option-label">
                <input
                  type="radio"
                  name={`${categoryName}-${question.id}`}
                  checked={responses[question.id] === option.key}
                  onChange={() => handleOptionChange(question.id, option.key)}
                />
                {option.text}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuizRadioButtons;
