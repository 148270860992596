import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
        textTransform: 'uppercase',
        textAlign: 'center',
        margin: theme.spacing(2),
        fontSize: '1.25rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
    },
}));

const Header = ({ children, variant="h5" }) => {  // Set default variant to "h5"
    const classes = useStyles();

    return (
        <Typography variant={variant} className={classes.header}>
            {children}
        </Typography>
    );
};

export default Header;
