import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import ReactCardFlip from "react-card-flip";
import styled from "@emotion/styled";

const fontColor = "#fff"; // common font color for both sides

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  flipCard: {
    height: "100%",
    cursor: "pointer",
  },
  flipCardFront: {
    backgroundColor: "#222932", // dark navy blue
    color: fontColor, // use common font color variable
  },
  flipCardBack: {
    backgroundColor: "#161B22", // slightly lighter navy blue
    color: fontColor, // use common font color variable
  },
  title: {
    fontWeight: 200, // Lighter font-weight
    fontSize: '1rem', // Set the font size to 1.26rem

  },
}));

const FlipCardGridContainer = styled(Grid)`
  background-color: #333;
  border-radius: 8px;
  padding: 2rem;
  color: ${fontColor}; // use common font color variable
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  margin: auto;
`;

const CardTitle = styled(Typography)`
  color: ${fontColor}; // use common font color variable
  font-weight: 300;
`;

const CardContentTypography = styled(Typography)`
  color: ${fontColor}; // use common font color variable
  font-weight: 300;
`;

const FlipCardGrid = ({ cardsContent }) => {
  const classes = useStyles();
  const [flipIndex, setFlipIndex] = useState(null);

  return (
    <FlipCardGridContainer container spacing={3}>
      {cardsContent.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ReactCardFlip
            isFlipped={flipIndex === index}
            flipDirection="vertical"
          >
            <Card
              onClick={() => setFlipIndex(index)}
              className={`${classes.flipCard} ${classes.flipCardFront}`}
            >
              <CardContent>
                <CardTitle gutterBottom variant="h5" component="h2">
                  {card.title}
                </CardTitle>
              </CardContent>
            </Card>

            <Card
              onClick={() => setFlipIndex(null)}
              className={`${classes.flipCard} ${classes.flipCardBack}`}
            >
              <CardContent>
                <CardContentTypography variant="body2" component="p">
                  {card.content}
                </CardContentTypography>
              </CardContent>
            </Card>
          </ReactCardFlip>
        </Grid>
      ))}
    </FlipCardGridContainer>
  );
};

export default FlipCardGrid;
