import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { TextField, Button, makeStyles, Typography, Link, Box, Paper, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#f5f5f5", // Match background with the section above
        padding: theme.spacing(5, 0), // Add padding to top and bottom
    },
    formContainer: {
        backgroundColor: "#ffffff", // White background for form container
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
        maxWidth: "600px",
        margin: "0 auto",
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    field: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
        backgroundColor: "#1e1e1e",
        color: "#fff",
        '&:hover': {
            backgroundColor: "#333",
        },
    },
    link: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    successMessage: {
        marginTop: theme.spacing(2),
        color: theme.palette.success.main,
    },
    errorMessage: {
        marginTop: theme.spacing(2),
        color: theme.palette.error.main,
    },
    contactDetails: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    contactEmail: {
        color: "#1e1e1e",
        fontWeight: 'bold',
    },
    contactLink: {
        color: "#1e1e1e",
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    }
}));

const ContactUs = () => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const templateId = 'template_8mqwp3c';
        const serviceID = 'service_ne4ipkw';

        let templateParams = {
            from_name: name,
            reply_to: email,
            message: message,
        };

        emailjs.send(serviceID, templateId, templateParams, 'lgC0Srzi9zC-oNFqD')
            .then(response => {
                console.log('Email successfully sent!', response);
                setSuccessMessage('Message sent successfully!');
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch(err => {
                console.error('Email sending failed.', err);
                setSuccessMessage('Oops, there was an error. Please try again later.');
            });
    }

    return (
        <div className={classes.container}>
            <Typography variant="h4" align="center" gutterBottom style={{color: '#333'}}>
                Contact Form
            </Typography>
            <Container className={classes.formContainer}>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField className={classes.field} label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required/>
                    <TextField className={classes.field} label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    <TextField className={classes.field} label="Message" variant="outlined" multiline rows={4} value={message} onChange={(e) => setMessage(e.target.value)} required/>
                    <Button className={classes.button} variant="contained" type="submit">
                        Send
                    </Button>
                    {successMessage && (
                        <Typography className={successMessage.includes('error') ? classes.errorMessage : classes.successMessage}>
                            {successMessage}
                        </Typography>
                    )}
                </form>
            </Container>
            <div className={classes.contactDetails}>
                <Typography variant="body1" color="textSecondary">
                    <Link href="mailto:office@perceptron.solutions" className={classes.contactEmail}>
                        office@perceptron.solutions
                    </Link>
                </Typography>
                <Typography className={classes.link}>
                    <Link href="https://www.linkedin.com/in/pavanmirla/" target="_blank" rel="noopener noreferrer" className={classes.contactLink}>
                        LinkedIn Profile
                    </Link>
                </Typography>
            </div>
        </div>
    )
}

export default ContactUs;
